import React from 'react'
import { IProcessObject } from 'types'
import Spreadsheet from 'components/spreadsheet'
import { PERMISSIONS } from 'helpers/auth'

export interface TableProps {
  blockProps: {
    tableId: string
    viewId: string
    process?: IProcessObject
    sectionId?: string
    responseId?: string
    height: number
  }
}

const Table: React.FC<TableProps> = (props) => {
  const { blockProps } = props
  let permissionCap = PERMISSIONS.contributor
  if (blockProps.process) {
    if (blockProps.process.permissionLevel <= PERMISSIONS.contributor) {
      permissionCap = blockProps.process.permissionLevel
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: `${blockProps.height}px`,
        marginBottom: '5px'
      }}
    >
      <Spreadsheet
        tableId={blockProps.tableId}
        tableViewId={blockProps.viewId}
        processId={blockProps.process ? blockProps.process.publicId : undefined}
        processSectionId={blockProps.sectionId ? blockProps.sectionId : undefined}
        processResponseId={blockProps.responseId ? blockProps.responseId : undefined}
        permissionCap={permissionCap}
        process={blockProps.process ? blockProps.process : undefined}
      />
    </div>
  )
}

export default Table
